<template>
  <b-overlay
      :show="messages === []"
      rounded="sm"
  >
    <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
    <div v-if="messages.length !== 0 && admin !== null" style="height: 85vh">
      <div
          class="body-content-overlay"

      />

      <!-- Main Area -->
      <section class="chat-app-window w-100">

        <!-- Chat Content -->
        <div

            class="active-chat"
        >
          <!-- Chat Navbar -->
          <div class="chat-navbar">
            <header class="chat-header">

              <!-- Avatar & Name -->
              <div class="d-flex align-items-center">

                <b-avatar
                    size="36"
                    :src="`https://mybitapi.oregonserver.com/${user.selfieFileData}`"
                    class="mr-1 cursor-pointer badge-minimal"
                />
                <h6 class="mb-0">
                  {{ user.name }} {{ user.familyName }}
                </h6>
              </div>

              <div>
                <b-button
                    v-if="!readMessage"
                    @click="toggleRead"
                    variant="primary"
                    type="submit"
                    class="mt-1 text-xs"
                >
                  پیام ها خوانده شوند
                </b-button>
                <b-button
                    v-else
                    @click="toggleRead"
                    variant="danger"
                    type="submit"
                    class="mt-1 text-xs"
                >
                  پیام ها خوانده نشوند
                </b-button>
              </div>
            </header>
          </div>

          <!-- User Chat Area -->
          <vue-perfect-scrollbar
              ref="refChatLogPS"
              :settings="perfectScrollbarSettings"
              class="user-chats scroll-area position-relative"
          >
            <!--          <vue-perfect-scrollbar-->
            <!--              ref="refChatLogPS"-->
            <!--              :settings="perfectScrollbarSettings"-->
            <!--              class="user-chats scroll-area"-->
            <!--              @scroll="handleScroll($event)"-->
            <!--          >-->


            <chat-log
                v-for="message in messages"
                :key="message.MessageId"
                :chat-data="message"
                :profile-user-avatar="`https://mybitapi.oregonserver.com/${admin.selfieFileData}`"
                :admin="admin"
                :user="user"
                @deleteItem="setDeleteItem"
            />

            <transition name="slide-up">
              <EmojiPicker class="emoji-container col-12 col-md-6" @SendEmoji="addEmoji" v-if="showEmojis"/>
            </transition>

          </vue-perfect-scrollbar>

          <!-- Message Input -->
          <div
              class="chat-app-form"
              @submit.prevent="createMessageData(0)"
          >

            <feather-icon @click=" showVoiceModal = true" v-b-modal.modal-voice class="text-danger mr-1" icon="MicIcon"
                          size="35"/>
            <feather-icon @click="showUploadModal = true" v-b-modal.modal-upload class="text-warning mr-1"
                          icon="PaperclipIcon" size="35"/>
            <feather-icon @click="showEmojis = !showEmojis" v-b-modal.modal-upload class="text-primary-dark mr-1"
                          icon="SmileIcon" size="35"/>
            <b-input-group class="input-group-merge w-100 form-send-message mr-1 ">
              <b-form-input
                  v-model="content"
                  @keydown.enter="createMessageData(0)"
                  placeholder="پاسخ شما..."
              />
            </b-input-group>

            <b-button
                class="btn"
                variant="primary"
                type="button"
                @click="createMessageData(0)"
            >
              <feather-icon class="text-white" icon="SendIcon" size="20"/>
            </b-button>
          </div>
        </div>
      </section>
    </div>

    <!--  Upload Pic Modal   -->
    <b-modal
        v-if="showUploadModal"
        id="modal-upload"
        centered
        title="بارگزاری عکس"
        ok-title="ارسال پیام"
        cancelTitle="انصراف"
        @cancel="closeUploadModal"
        @ok="createMessageData(1)"
    >

      <b-media class="my-2 col-12 mx-auto">
        <template>
          <b-avatar
              class="w-100 cursor-pointer mx-auto"
              ref="previewEl"
              :src="base64ImageSrc"
              size="300px"
              rounded
              @click.native="$refs.refInputEl1.click()"
          >
            <feather-icon v-if="base64ImageSrc === null" icon="PlusSquareIcon" size="100"/>
          </b-avatar>
        </template>
        <div class="d-none flex-wrap mt-1">
          <b-button
              variant="primary"
          >
            <input
                ref="refInputEl1"
                type="file"
                class="d-none"
                accept="image/*"
                @input="makeBase64Pic($event,'base64ImageSrc')"
            >
            <span class="d-none d-sm-inline">+</span>
            <feather-icon
                icon="EditIcon"
                class="d-inline d-sm-none"
            />
          </b-button>
        </div>
      </b-media>

      <b-input-group class="input-group-merge form-send-message">
        <b-form-input
            v-model="picText"
            placeholder="پیام شما..."
        />
      </b-input-group>
    </b-modal>
    <!--  Upload Pic Modal End  -->

    <!-- Voice Message   -->
    <b-modal
        v-if="showVoiceModal"
        id="modal-voice"
        centered
        title="ارسال صوت"
        :ok-disabled="showVoiceSubmit ? true : false"
        ok-title="ارسال"
        cancelTitle="انصراف"
        @cancel="showVoiceModal = !showVoiceModal"
        @ok="createMessageData(2)"
    >

      <AudioRecorder @getAudioBlob="SetAudioBlob" ref="Recorder"/>

      <div style="height: 10rem" class="w-100 d-flex flex-column align-items-center justify-content-center rounded-6 ">
        <b-button v-if="!IsRecording" variant="primary" type="button" @click.stop="StartRecording">
          <feather-icon class="text-white" icon="PlayIcon" size="20"/>
        </b-button>
        <div v-else @click="StopRecording" class="blob red"></div>
        <div class="flex items-center flex-row-reverse gap-3 my-2">
          <small>{{ Seconds }}</small>
          :
          <small>{{ Minutes }}</small>
          :
          <small>{{ Hours }}</small>
        </div>
        <audio v-if="VoiceMessage!==''" :src="GenerateAudioSrc" controls></audio>
      </div>

    </b-modal>
    <!--  Voice Message End  -->

    <!-- Delete Message   -->
    <b-modal
        id="modal-delete"
        centered
        ok-title="حذف"
        cancelTitle="انصراف"
        @ok="deleteMessage(deleteItem)"
    >
      <span>حذف شود؟</span>
    </b-modal>
    <!--  Delete Message End  -->

  </b-overlay>
</template>

<script>
import store from '@/store'
import {
  ref, onUnmounted, nextTick,
} from '@vue/composition-api'
import {
  BAvatar, BDropdown, BDropdownItem, BForm, BInputGroup, BFormInput, BButton, BOverlay, BModal, BMedia
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
// import { formatDate } from '@core/utils/filter'
import {$themeBreakpoints} from '@themeConfig'
import {useResponsiveAppLeftSidebarVisibility} from '@core/comp-functions/ui/app'
import chatStoreModule from './chatStoreModule'
import ChatLog from "@/views/apps/chat/ChatLog";
import useChat from './useChat'
import {UsersGeByTokenRequest} from "@/libs/Api/Users";
import {MessageDeleteRequest, MessageGetConversationRequest} from "@/libs/Api/Message";
import {MessageSendRequest} from "@/libs/Api/Message";
import {UsersGetRequest} from "@/libs/Api/Users";
import {MessageReadRequest} from "@/libs/Api/Message";
import {mapGetters} from "vuex";
import AudioRecorder from "@/views/components/chat/AudioRecorder";
import EmojiPicker from "@/views/components/chat/EmojiPicker";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
  title:"Chat",
  data() {
    return {
      messageData: null,
      userId: this.$route.params.id,
      base64ImageSrc: null,
      showUploadModal: false,
      showVoiceModal: false,
      showEmojis: false,
      user: null,
      messages: [],
      admin: null,
      refreshTicket: null,
      content: '',
      hasScrolledToTop: false,
      readMessage: false,
      pic: null,
      picText: '',
      showVoiceSubmit: true,
      deleteItem:null,

      // Voice variables
      IsRecording: false,
      Hours: 0,
      Minutes: 0,
      Seconds: 0,
      AudioStopWatch: null,
      VoiceMessage: '',
      fileData: {
        base64: '',
        baseImgUrl: '',
        priority: 0
      }
    }
  },
  computed: {
    GenerateAudioSrc() {
      if (this.VoiceMessage) {
        return URL.createObjectURL(this.VoiceMessage)
      }
    },
    currentDate() {
      let today = new Date();
      let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
      let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      return date + ' ' + time;
    },
    ...mapGetters(["SocketId"]),
  },
  watch: {
    SocketId: function (val, oldVal) {
      this.getConversation(0);
      this.$store.commit("SetDefaultSocketId");
    },
    hasScrolledToTop: function (val, oldVal) {
      if (val) {
        if (this.messages.length > 0) {
          const fIdx = this.messages[0].MessageId;
          this.getConversation(fIdx);
        }
      }
    },
  },
  async created() {
    await this.getConversation(0);
    await this.getUser();
    await this.getAdmin();
    this.scrollToBottom()
  },
  methods: {
    addEmoji(param) {
      this.content += param.emoji
    },
    createMessageData(priority) {
      let _this = this;

      if (priority === 0) {
        _this.messageData = {
          messageId: 0,
          isDeleted: false,
          subject:
              _this.content.length > 20
                  ? _this.content.substr(0, 20)
                  : _this.content,
          messageBody: _this.content,
          parentMessageId: null,
          recipientUserId: Number(_this.$route.params.id),
          recipientGroupId: null,
          isForwarded: false,
          createDate: _this.currentDate,
          userId: 0,
        };
        _this.SendMessage();
      }

      if (priority === 1) {
        _this.messageData = {
          messageId: 0,
          isDeleted: false,
          subject:
              _this.content.length > 20
                  ? _this.content.substr(0, 20)
                  : _this.content,
          messageBody: _this.picText,
          parentMessageId: null,
          recipientUserId: Number(_this.$route.params.id),
          recipientGroupId: null,
          isForwarded: false,
          createDate: _this.currentDate,
          userId: 0,
          fileData: {
            base64: _this.pic,
            priority: 1
          },
          mediaUrl: ""
        };
        _this.SendMessage();
      }

      if (priority === 2) {
        _this.messageData = {
          messageId: 0,
          isDeleted: false,
          subject:
              _this.content.length > 20
                  ? _this.content.substr(0, 20)
                  : _this.content,
          messageBody: '',
          parentMessageId: null,
          recipientUserId: Number(_this.$route.params.id),
          recipientGroupId: null,
          isForwarded: false,
          createDate: _this.currentDate,
          userId: 0,
          fileData: {
            base64: _this.fileData.base64,
            priority: 2
          },
          mediaUrl: ""
        };
        _this.SendMessage();
      }
    },
    // Voice Message Methods
    StopRecording() {
      clearInterval(this.AudioStopWatch)
      this.IsRecording = false
      this.Seconds = 0
      this.Hours = 0
      this.Minutes = 0
      this.$refs.Recorder.stopRecording()
      this.showVoiceSubmit = false;
      // this.$refs.AudioR.click();

    },
    SetAudioBlob(blob) {
      this.VoiceMessage = blob
      let that = this
      const reader = new FileReader()
      reader.onload = (function (theFile) {
        return function () {
          const binaryData = reader.result
          that.fileData.base64 = window.btoa(binaryData)
          that.fileData.priority = 2
        }
      })(blob)
      reader.readAsBinaryString(blob)
    },
    StartRecording() {
      this.IsRecording = true
      if (this.IsRecording) {
        this.AudioStopWatch = setInterval(() => {
          this.Seconds++
          if (this.Seconds === 59) {
            this.Seconds = 0
            this.Minutes++
            if (this.Minutes === 59) {
              this.Hours++
            }
          }
        }, 1000)
        this.$refs.Recorder.startRecording()
        // this.$refs.AudioR.click();
      }
    },
    async readAsDataURL(file) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onerror = reject;
        fr.onload = () => {
          resolve(fr.result.split(",")[1]);
        }
        fr.readAsDataURL(file);
      });
    },
    async makeBase64Pic(e, index) {
      const _this = this;
      let file = e.target.files[0];
      _this[index] = URL.createObjectURL(file);
      const result = await _this.readAsDataURL(file);
      _this.pic = result;
    },
    closeUploadModal() {
      this.pic = null;
      this.picText = null;
      this.base64ImageSrc = null;
      this.showUploadModal = false;
    },
    uploadVoice() {
      let _this = this;

      console.log(this.voice);
    },
    async SendMessage() {
      let _this = this;

      let messageSendRequest = new MessageSendRequest(_this);
      messageSendRequest.setParams(_this.messageData)
      await messageSendRequest.fetch(function (content) {
        _this.content = '';
        _this.pic = null;
        _this.picText = '';
        _this.fileData.base64 = null;
        _this.VoiceMessage = '';
        _this.showEmojis = false;
        _this.getConversation(0);
      }, function (error) {
        console.log(error);
      })
    },
    showModal(param) {
      let _this = this;

      _this.showUploadModal = true;
      _this.deleteItem = param
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.refChatLogPS.$el;
        container.scrollTop = container.scrollHeight;
      })
    },
    handleScroll: function ({target: {scrollTop, clientHeight, scrollHeight},}) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.Atbottom = true;
      } else {
        this.Atbottom = false;
      }

      if (scrollTop === 0) {
        this.hasScrolledToTop = true;
      } else {
        this.hasScrolledToTop = false;
      }
    },
    async getConversation(mId) {
      let _this = this;
      let data = {
        userId: _this.$route.params.id,
        count: 40,
        MessageId: mId,
      }

      let messageGetConversationRequest = new MessageGetConversationRequest(_this);
      messageGetConversationRequest.setData(data)
      await messageGetConversationRequest.fetch(function (content) {
        _this.messages = [];
        content.forEach(el => {
          _this.messages.push(el);
        })
        if (_this.readMessage) {
          _this.readMessages();
        }
        _this.messages.sort(function (a, b) {
          const key1 = a.createDate;
          const key2 = b.createDate;

          if (key1 < key2) {
            return -1;
          } else if (key1 === key2) {
            return 0;
          } else {
            return 1;
          }
        });
        // _this.scrollToBottom()
      }, function (error) {
        console.log(error);
      })
    },
    async getUser() {
      let _this = this;

      if (_this.messages !== null) {
        let usersGetRequest = new UsersGetRequest(_this);
        usersGetRequest.setUserId(_this.userId);
        await usersGetRequest.fetch(function (content) {
          _this.user = content
        }, function (error) {
          console.log(error)
        })
      }
    },
    async getAdmin() {
      let _this = this;

      let usersGeByTokenRequest = new UsersGeByTokenRequest(_this);
      await usersGeByTokenRequest.fetch(function (content) {
        _this.admin = content
      }, function (error) {
        console.log(error);
      })
    },
    async readMessages() {
      let _this = this;

      let messageReadRequest = new MessageReadRequest(_this);
      messageReadRequest.setId(_this.userId);
      await messageReadRequest.fetch(function (content) {

      }, function (error) {
        console.log(error)
      })
    },
    toggleRead() {
      let _this = this;

      _this.readMessage = !_this.readMessage;
      if (_this.readMessage === true) {
        _this.readMessages();
      }
    },
    setDeleteItem(param){
      this.deleteItem = param;
    },
    async deleteMessage(param){
      let _this = this;

      let messageDeleteRequest = new MessageDeleteRequest(_this);
      messageDeleteRequest.setId(param)
      await messageDeleteRequest.fetch(function (content){
        console.log(content);
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `پیام حذف شد.`,
          },
        });
        _this.deleteItem = null;
        _this.getConversation(0);
      },function (error){
        console.log(error);
      })
    },
  },
  components: {
    EmojiPicker,
    AudioRecorder,
    ChatLog,

    // BSV
    BAvatar,
    BDropdown,
    BDropdownItem,
    BForm,
    BInputGroup,
    BFormInput,
    BButton,
    BOverlay,
    BModal,
    BMedia,

    // 3rd Party
    VuePerfectScrollbar,
  },
  setup() {
    const CHAT_APP_STORE_MODULE_NAME = 'app-chat'

    // Register module
    if (!store.hasModule(CHAT_APP_STORE_MODULE_NAME)) store.registerModule(CHAT_APP_STORE_MODULE_NAME, chatStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CHAT_APP_STORE_MODULE_NAME)) store.unregisterModule(CHAT_APP_STORE_MODULE_NAME)
    })

    const {resolveAvatarBadgeVariant} = useChat()

    // Scroll to Bottom ChatLog
    const refChatLogPS = ref(null)
    const scrollToBottomInChatLog = () => {
      const scrollEl = refChatLogPS.value.$el || refChatLogPS.value
      scrollEl.scrollTop = scrollEl.scrollHeight
    }

    // ------------------------------------------------
    // Chats & Contacts
    // ------------------------------------------------
    const chatsContacts = ref([])
    const contacts = ref([])

    const fetchChatAndContacts = () => {
      store.dispatch('app-chat/fetchChatsAndContacts')
          .then(response => {
            chatsContacts.value = response.data.chatsContacts
            contacts.value = response.data.contacts
            // eslint-disable-next-line no-use-before-define
            profileUserDataMinimal.value = response.data.profileUser
          })
    }

    fetchChatAndContacts()

    // ------------------------------------------------
    // Single Chat
    // ------------------------------------------------
    const activeChat = ref({})
    const chatInputMessage = ref('')
    const openChatOfContact = userId => {
      // Reset send message input value
      chatInputMessage.value = ''

      store.dispatch('app-chat/getChat', {userId})
          .then(response => {
            activeChat.value = response.data

            // Set unseenMsgs to 0
            const contact = chatsContacts.value.find(c => c.id === userId)
            if (contact) contact.chat.unseenMsgs = 0

            // Scroll to bottom
            nextTick(() => {
              scrollToBottomInChatLog()
            })
          })

      // if SM device =>  Close Chat & Contacts left sidebar
      // eslint-disable-next-line no-use-before-define
      mqShallShowLeftSidebar.value = false
    }
    const sendMessage = () => {
      if (!chatInputMessage.value) return
      const payload = {
        contactId: activeChat.value.contact.id,
        // eslint-disable-next-line no-use-before-define
        senderId: profileUserDataMinimal.value.id,
        message: chatInputMessage.value,
      }
      store.dispatch('app-chat/sendMessage', payload)
          .then(response => {
            const {newMessageData, chat} = response.data

            // ? If it's not undefined => New chat is created (Contact is not in list of chats)
            if (chat !== undefined) {
              activeChat.value = {chat, contact: activeChat.value.contact}
              chatsContacts.value.push({
                ...activeChat.value.contact,
                chat: {
                  id: chat.id,
                  lastMessage: newMessageData,
                  unseenMsgs: 0,
                },
              })
            } else {
              // Add message to log
              activeChat.value.chat.chat.push(newMessageData)
            }

            // Reset send message input value
            chatInputMessage.value = ''

            // Set Last Message for active contact
            const contact = chatsContacts.value.find(c => c.id === activeChat.value.contact.id)
            contact.chat.lastMessage = newMessageData

            // Scroll to bottom
            nextTick(() => {
              scrollToBottomInChatLog()
            })
          })
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    // User Profile Sidebar
    // ? Will contain all details of profile user (e.g. settings, about etc.)
    const profileUserData = ref({})
    // ? Will contain id, name and avatar & status
    const profileUserDataMinimal = ref({})

    const shallShowUserProfileSidebar = ref(false)
    const showUserProfileSidebar = () => {
      store.dispatch('app-chat/getProfileUser')
          .then(response => {
            profileUserData.value = response.data
            shallShowUserProfileSidebar.value = true
          })
    }

    // Active Chat Contact Details
    const shallShowActiveChatContactSidebar = ref(false)

    // UI + SM Devices
    // Left Sidebar Responsiveness
    const {mqShallShowLeftSidebar} = useResponsiveAppLeftSidebarVisibility()
    const startConversation = () => {
      if (store.state.app.windowWidth < $themeBreakpoints.lg) {
        mqShallShowLeftSidebar.value = true
      }
    }

    return {
      // Filters
      // formatDate,

      // useChat
      resolveAvatarBadgeVariant,

      // Chat & Contacts
      chatsContacts,
      contacts,

      // Single Chat
      refChatLogPS,
      activeChat,
      chatInputMessage,
      openChatOfContact,
      sendMessage,

      // Profile User Minimal Data
      profileUserDataMinimal,

      // User Profile Sidebar
      profileUserData,
      shallShowUserProfileSidebar,
      showUserProfileSidebar,

      // Active Chat Contact Details
      shallShowActiveChatContactSidebar,

      // UI
      perfectScrollbarSettings,

      // UI + SM Devices
      startConversation,
      mqShallShowLeftSidebar,
    }
  },
}
</script>

<style>
.blob {
  background: #FF5252B2;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  margin: 10px;
  height: 20px;
  width: 20px;
  transform: scale(1);
  animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

/*@media only screen and (max-width: 576px) {*/
/*  *{*/
/*    font-size: .7rem;*/
/*  }*/
/*}*/
.emoji-container {
  position: sticky;
  bottom: 0;
  left: 0;
}


.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.25s ease-out;
}

.slide-up-enter-from {
  opacity: 0;
  transform: translateY(30px);
}

.slide-up-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";
</style>
